/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import idx from 'idx'
import Modal from '@material-ui/core/Modal'
import DialogContent from '@material-ui/core/DialogContent'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../../utils/GatsbyLink'
import { theme } from '../../styles'
import CrossIcon from '../../../static/icons/misc-icons/close-icon.svg'
import Space from '../common/Space'
import Text from '../common/Text'
import Button from '../common/Button'
import TextLink from '../common/TextLink'
import AnimatedVideo from '../common/AnimatedVideo'
import VimeoIframe from '../common/VimeoIframe'
import getImage from '../../utils/getImage'
import LottieAnimation from '../LottieAnimation'
import violetCheckMarkIcon from '../../../static/icons/check-icons/violet-check-icon.svg'

const BG = styled.div`
  position: absolute;
  width: 500vw;
  margin-left: -50%;
  height: 100%;
  z-index: -1;
  background-color: ${p => (p.backgroundColor ? theme.color[p.backgroundColor] : theme.color.white)};
  margin-top: ${p => (p.hasTopPadding ? '-80px' : '0px')};

  @media (max-width: 1050px) {
    margin-top: ${p => (!p.hasBottomPadding && p.hasTopPadding ? '-56px' : '0px')};
  }
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${p => (!p.reverse ? 'row' : 'row-reverse')};
  justify-content: center;
  min-height: ${p => (p.hasPadding ? '488px' : 'unset')};
  width: calc(100% + 300px);
  margin-left: -150px;
  padding: 0px 150px;
  padding-top: ${p => (p.hasTopPadding ? '80px' : '0px')};
  padding-bottom: ${p => (p.hasBottomPadding ? '80px' : '0px')};
  z-index: 0;
  position: relative;

  @media (max-width: 1050px) {
    width: 100%;
    margin-left: 0px;
    flex-direction: column;
    padding-top: ${p => (p.hasTopPadding ? '56px' : '0px')};
    padding-bottom: ${p => (p.hasBottomPadding ? '56px' : '0px')};
    padding-left: 16px;
    padding-right: 16px;
    height: auto;
  }

  @media (max-width: 800px) {
    padding-left: 0px;
    padding-right: 0px;
  }

  .hide-desktop {
    display: none;
    * {
      text-align: center;
    }

    @media (max-width: 800px) {
      display: block;
    }
  }
  .hide-mobile {
    display: block;
    @media (max-width: 800px) {
      display: none;
    }
  }
`

const ImageWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  display: flex;
  width: 100%;
  object-fit: contain;
  margin: 0 auto;

  @media (max-width: 1050px) {
    max-width: 500px;
    width: ${p => (p.hasVideo ? '80%' : '100%')};
  }

  @media (max-width: 800px) {
    width: ${p => (p.hasVideo ? '95%' : '100%')};
    max-width: unset;
  }
`

const LottieWrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1050px) {
    max-width: 500px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow-wrap: anywhere;
  max-width: 100%;

  ul {
    margin-left: 20px;
    li {
      text-align: left !important;
    }
  }

  @media (max-width: 1050px) {
    max-width: 700px;
    * {
      text-align: center !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const InfoOutWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`

const CheckMarkListWrapper = styled(Text)`
  ul {
    margin-block-start: 8px;
    margin-block-end: 8px;
  }
  li {
    padding-inline-start: 12px;
    list-style-type: none;
    list-style-image: url(${violetCheckMarkIcon});
  }

  @media (max-width: 800px) {
    li {
      margin-bottom: 16px;
    }
  }
`

export const ModalWrapper = styled(DialogContent)`
  height: 100vh;
  padding: 0px;
  overflow-y: unset;
  position: relative;
`

export const CloseIcon = styled.img`
  width: 32px;
  height: 32px;
  padding: 9px;
  position: absolute;
  right: 20%;
  top: 22%;
  background-color: ${theme.color.white};
  border-radius: 50%;
  z-index: 9999;

  @media (max-width: 1900px) {
    top: 29%;
  }

  @media (max-width: 1300px) {
    top: 33%;
  }
  @media (max-width: 1050px) {
    top: 28%;
    right: 4%;
  }

  @media (max-width: 800px) {
    top: 31%;
  }
`

const LinkWrapper = styled.div`
  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`

const Feature = ({ primary }) => {
  const text = <RichText render={primary.text.richText} serializeHyperlink={GatsbyLink} />
  const checkMarkList = <RichText render={primary.checkmark_list.richText} serializeHyperlink={GatsbyLink} />
  const subtitle = <RichText render={primary.subtitle.richText} serializeHyperlink={GatsbyLink} />
  const title = <RichText render={primary.title.richText} serializeHyperlink={GatsbyLink} />
  const imageSrc = idx(primary, _ => _.image.url)
  const reverse = primary.is_revert
  const video = idx(primary, _ => _.video.url)
  const animatedVideo = idx(primary, _ => _.animated_video.url)
  const buttonText = idx(primary, _ => _.button_text.text)
  const buttonUrl = idx(primary, _ => _.button_link.url)
  const textLinkText = idx(primary, _ => _.text_link_text.text)
  const textLinkUrl = idx(primary, _ => _.text_link.url)
  const backgroundColor = primary.background_color
  const hasTopPadding = idx(primary, _ => _.has_top_padding)
  const hasBottomPadding = idx(primary, _ => _.has_bottom_padding)
  const anchorId = primary.anchor_id
  const rawLottie = primary.animation && primary.animation.text
  const lottieAnim = rawLottie ? JSON.parse(primary.animation.text) : null
  const lottieAriaLabel = idx(primary, _ => _.animation_aria_label)

  const [open, setOpen] = useState(false)

  return (
    <Wrapper
      id={anchorId || null}
      reverse={reverse}
      hasTopPadding={hasTopPadding === null ? true : hasTopPadding}
      hasBottomPadding={hasBottomPadding === null ? true : hasBottomPadding}
      backgroundColor={backgroundColor}
    >
      <div className="hide-desktop">
        {title && idx(title, _ => _.props.render[0].text) && (
          <>
            <Text font="heading3" richText>
              {title}
            </Text>
            <Space height={16} mobileHeight={24} />
          </>
        )}
        {subtitle && idx(subtitle, _ => _.props.render[0].text) && (
          <>
            <Text font="bodyHeavy" richText>
              {subtitle}
            </Text>
            <Space height={16} />
          </>
        )}
      </div>
      {(imageSrc || lottieAnim) && !animatedVideo && (
        <ImageWrapper reverse={reverse}>
          {video ? (
            <a
              tabIndex="0"
              role="button"
              aria-label="Play AudioEye video opens in a modal"
              onKeyDown={e => {
                if (e.key === 'Enter') setOpen(true)
              }}
              onClick={() => video && setOpen(true)}
              style={{ width: '100%' }}
            >
              {getImage(primary.image, Image, { hasVideo: video.length > 0 })}
            </a>
          ) : lottieAnim && typeof lottieAnim === 'object' ? (
            <LottieWrapper>
              <LottieAnimation lotti={lottieAnim} lottieAriaLabel={lottieAriaLabel} />
            </LottieWrapper>
          ) : (
            getImage(primary.image, Image)
          )}
        </ImageWrapper>
      )}
      {animatedVideo && <AnimatedVideo videoSrc={animatedVideo} poster={imageSrc} />}
      {(animatedVideo || imageSrc || video || lottieAnim) && <Space width={80} tabletHeight={24} />}
      <InfoOutWrapper tabindex="-1" reverse={reverse}>
        <InfoWrapper>
          <div className="hide-mobile">
            {title && idx(title, _ => _.props.render[0].text) && (
              <>
                <Text font="heading3" richText>
                  {title}
                </Text>
                <Space height={16} />
              </>
            )}
            {subtitle && idx(subtitle, _ => _.props.render[0].text) && (
              <>
                <Text font="heading4" richText>
                  {subtitle}
                </Text>
                <Space height={16} />
              </>
            )}
          </div>
          {text && idx(text, _ => _.props.render[0].text) && (
            <>
              <Text font="body" richText>
                {text}
              </Text>
              {checkMarkList && idx(checkMarkList, _ => _.props.render[0].text) ? (
                <Space height={4} />
              ) : (
                <Space height={12} />
              )}
            </>
          )}
          {checkMarkList && idx(checkMarkList, _ => _.props.render[0].text) && (
            <>
              <CheckMarkListWrapper richText>{checkMarkList}</CheckMarkListWrapper>
              <Space height={24} />
            </>
          )}
          {textLinkUrl && (
            <LinkWrapper>
              <TextLink text={textLinkText} to={textLinkUrl} />
            </LinkWrapper>
          )}
          {buttonUrl && !textLinkUrl && <Button text={buttonText} to={buttonUrl} />}
        </InfoWrapper>
      </InfoOutWrapper>
      {video && (
        <Modal open={(video && open) === true} onClose={() => video && setOpen(false)} aria-label="Video modal">
          <ModalWrapper>
            <CloseIcon
              tabIndex="0"
              src={CrossIcon}
              aria-label="close"
              role="button"
              className="focus-outline"
              onClick={() => video && setOpen(false)}
              onKeyDown={e => {
                if (e.key === 'Enter') setOpen(false)
              }}
            />
            <VimeoIframe isModal allowFullScreen video={video} autoPlay={false} />
          </ModalWrapper>
        </Modal>
      )}
      <BG
        className="bg"
        backgroundColor={backgroundColor}
        hasTopPadding={hasTopPadding === null ? true : hasTopPadding}
        hasBottomPadding={hasBottomPadding === null ? true : hasBottomPadding}
      />
    </Wrapper>
  )
}

export default Feature

export const query = graphql`
  fragment Feature on PrismicPageDataBodyFeature {
    ...SliceBase
    id
    primary {
      background_color
      has_top_padding
      has_bottom_padding
      anchor_id
      is_revert
      button_link {
        uid
        url
      }
      button_text {
        ...TextContent
      }
      text_link {
        uid
        url
      }
      text_link_text {
        ...TextContent
      }
      video {
        url
      }
      animated_video {
        url
      }
      image {
        alt
        url
        gatsbyImageData(width: 540)
      }
      text {
        ...TextContent
      }
      checkmark_list {
        ...TextContent
      }
      subtitle {
        ...TextContent
      }
      title {
        ...TextContent
      }
      animation {
        ...TextContent
      }
      animation_aria_label
    }
  }

  fragment PostFeature on PrismicPostDataBodyFeature {
    ...SliceBase
    id
    primary {
      background_color
      has_top_padding
      has_bottom_padding
      anchor_id
      is_revert
      button_link {
        uid
        url
      }
      button_text {
        ...TextContent
      }
      text_link {
        uid
        url
      }
      text_link_text {
        ...TextContent
      }
      video {
        url
      }
      animated_video {
        url
      }

      image {
        alt
        url
        gatsbyImageData(width: 540)
      }
      text {
        ...TextContent
      }
      checkmark_list {
        ...TextContent
      }
      subtitle {
        ...TextContent
      }
      title {
        ...TextContent
      }
      animation {
        ...TextContent
      }
      animation_aria_label
    }
  }
`
